import "reflect-metadata";

import "core/assets/css/reset.css";
import "core/assets/css/core.css";

import React, {ComponentType, lazy, Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {createRoot} from "react-dom/client";
import {StyledEngineProvider} from "@mui/material/styles";
import {PagePreloader} from "core/views/components/preloader";
import {retryFailLoad} from "core/data/utils";

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

const ProjectIndex = lazy(
	retryFailLoad(
		() =>
			import(`clients/${process.env.REACT_APP_PROJECT_NAME || ""}`) as unknown as Promise<{
				default: ComponentType<unknown>;
			}>
	)
);

if (process.env.REACT_APP_PROJECT_NAME) {
	createRoot(root).render(
		<StyledEngineProvider injectFirst>
			<Suspense fallback={<PagePreloader />}>
				<ProjectIndex />
			</Suspense>
		</StyledEngineProvider>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
