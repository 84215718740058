export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	LEAGUE_JOIN,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum TournamentStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
	STORE = "store",
	STATE = "state",
	USER_TYPE = "user_type",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum NavigationSubMenuType {
	LEAGUES = "league",
	HELP = "help",
}

export enum TippingType {
	NRL = "nrl",
	AFL = "afl",
}

export enum AdvertisementPlace {
	LandingPageBelowGameTileDesktop = "landing_page_below_game_tile_desktop",
	LandingPageBelowGameTileMobile = "landing_page_below_game_tile_mobile",
	GameplayBelowCompetitionLadderDesktop = "gameplay_below_competition_ladder_desktop",
	GameplayBottomOfPageMobile = "gameplay_bottom_of_page_mobile",
	LeaguesRightSideOfPageDesktop = "leagues_right_side_of_page_desktop",
	LeaguesBottomOfPageDesktop = "leagues_bottom_of_page_desktop",
	LeaguesBottomOfPageMobile = "leagues_bottom_of_page_mobile",
	RankingsRightSideOfPageDesktop = "rankings_right_side_of_page_desktop",
	RankingsBottomOfPageDesktop = "rankings_bottom_of_page_desktop",
	RankingsBottomOfPageMobile = "rankings_bottom_of_page_mobile",
	AccountBottomOfPageDesktop = "account_bottom_of_page_desktop",
	AccountBottomOfPageMobile = "account_bottom_of_page_mobile",
	GlobalRightSideOfPageDesktop = "global_right_side_of_page_desktop",
	GlobalBottomOfPageDesktop = "global_bottom_of_page_desktop",
	GlobalBottomOfPageMobile = "global_bottom_of_page_mobile",
}

export enum LeaderboardSortOrder {
	Rank = "rank",
	// RoundRank = "roundRank",
	Team = "team",
	Points = "points",
	Margin = "margin",
	AvgPoints = "avgPoints",
	RoundPoints = "roundPoints",
	RoundMargin = "roundMargin"
}