export const ORIGIN = process.env.REACT_APP_ORIGIN || `${window.location.origin}/`;
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const PROJECT_JSON_URL = process.env.REACT_APP_PROJECT_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const GAME_SLUG = process.env.REACT_APP_GAME_SLUG || "";
export const GAME_NAME = process.env.REACT_APP_NAME || "";
export const FORM_GUIDE_ENABLED = JSON.parse(
	process.env.REACT_APP_FORM_GUIDE_ENABLED || "true"
) as boolean;
export const MIN_REGISTRATION_AGE = JSON.parse(
	process.env.REACT_APP_MIN_REGISTRATION_AGE || "0"
) as number;

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";
export const CONFIRM_PASSWORD_REQUIREMENTS = `${PASSWORD_REQUIREMENTS}. It must also match the entered password above`;

export const MAX_MARGIN_VALUE = Number(process.env.REACT_APP_MAX_MARGIN_VALUE || 99);
export const MIN_MARGIN_VALUE = Number(process.env.REACT_APP_MIN_MARGIN_VALUE || 1);
export const AUSTRALIA_COUNTRY_CODE = "AUS";

export const JOINED_GAME_NAME = GAME_NAME.split(" ").join("_");
